import React, { useEffect, useState } from "react";
import { Layout } from "../components/utilityComponents/layout";
import { MainPageHeader as AboutUsPageHeader } from "../components/mainPageComponents/mainPageHeader";
import { graphql, StaticQuery } from "gatsby";
import { ImageWithText } from "../components/utilityComponents/imageWithText";
import { Button } from "../components/utilityComponents/button";
import { useWidth } from "../hooks/useWidth";
import { goToUrl } from "../helpers/goToUrl";

export default function AboutUsPage() {
  const { isDesktop } = useWidth();

  return (
    <StaticQuery
      query={graphql`
        {
          strapiAboutUs {
            sectionWithImage {
              id
              title
              isLogo
              description
              isButton
              heroImage {
                url
              }
            }
          }
        }
      `}
      render={({ strapiAboutUs: { sectionWithImage } }) => {
        return (
          <Layout
              film={true}
            href="#2"
            darkBackground
            fullHeightBackground
            headerChildren={<AboutUsPageHeader />}
          >
            <div>
              {sectionWithImage.map((props: any, index: number) => {
                return (
                  <ImageWithText
                      {...props}
                      revert={index % 2 == 1 ? true : false}
                      index={index}
                  />
                );
              })}
            </div>
            <div className="onas__buttonContainer">
              <Button
                style={{ margin: isDesktop ? "2rem 1rem" : "5px 21px" }}
                label="Zobacz menu"
                size="large"
                type="secondary"
                onClick={() => goToUrl("/menu")}

              />
              <Button
                  onClick={() => window.location.href = 'https://koneser.s4honline.pl/'}
                style={{ margin: isDesktop ? "2rem 1rem" : "5px 21px" }}
                label="Zamów online"
                size="large"
                type="primary"
              />
            </div>
          </Layout>
        );
      }}
    />
  );
}
